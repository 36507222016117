.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4242422e;
    z-index: 9999;
    transition: background-color 3s ease;
}

.dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #01505D, #0097AF);
    animation: dot-bounce 1.2s infinite ease-in-out both;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes dot-bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}